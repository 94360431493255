<template lang="html">
  <section class="home">
    <div class="cabecera">
      <img src="/img/wave-white.svg" alt="decoracion" class="decoration" />
      <div class="container">
        <a class="btn-bases" target="_blank" download="" href="">LOREM IPSUM</a>
        <a class="btn-bases" target="_blank" download="" href="">LOREM IPSUM</a>
        <a class="btn-bases" target="_blank" download="" href="">LOREM IPSUM</a>
        <a class="btn-bases" target="_blank" download="" href="">LOREM IPSUM</a>
        <a class="btn-bases" target="_blank" download="" href="">LOREM IPSUM</a>
        <a class="btn-bases" target="_blank" download="" href="">LOREM IPSUM</a>
      </div>
      <div class="container">
        <a class="btn-bases -blue" target="_blank" download="" href=""
          >LOREM IPSUM</a
        >
      </div>
    </div>

    <div class="container" id="ancla1">
      <div class="container cuerpo">
        <div class="titulo">
          <h2>Lorem ipsum dolor sit amet</h2>
        </div>
        <p
          style="
            align-items: center;
            display: none;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          "
        ></p>
        <div class="ofertas">
          <div v-for="(offer, key) in $tm('offers')" :key="key">
            <router-link
              :to="{
                name: 'oferta',
                params: {
                  slug: offer.slug,
                },
              }"
            >
              <h4>{{ offer.title }}</h4>
              <p>{{ offer.header }}</p>

              <p class="btn-bases -blue">LOREM IPSUM</p>
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <div class="footer" id="ancla2">
      <h2>Lorem ipsum dolor sit amet</h2>
      <h4>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc nisl dui,
        pharetra eget sodales sit amet, posuere nec magna. Pellentesque ut nibh
        lacus. Donec odio nisl, venenatis rhoncus sagittis ac, sodales nec arcu.
      </h4>
      <p>
        ELorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc nisl dui,
        pharetra eget sodales sit amet, posuere nec magna. Pellentesque ut nibh
        lacus. Donec odio nisl, venenatis rhoncus sagittis ac, sodales nec arcu.
        In faucibus enim tellus, id rutrum libero consequat sed. Vivamus
        ullamcorper scelerisque quam, vel placerat nibh vulputate eget.
        Phasellus non venenatis purus. Pellentesque habitant morbi tristique
        senectus et netus et malesuada fames ac turpis egestas. Donec id
        pulvinar metus, quis iaculis nulla. Proin ullamcorper ligula nisl.
      </p>
    </div>
  </section>
</template>

<script lang="js">
export default {
  name: 'home',
  props: ['slug'],
}
</script>
